.webinars {
	width: 80%;
	padding: 50px;
	margin: 0 auto;
	text-align: center;
}

.webinars h2 {
	padding: 20px;
}

.postercards-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.poster-card {
	max-width: 350px;
	height: fit-content;
	margin: 30px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.poster {
	width: 100%;
	height: auto;
}

.poster-url {
	width: 100%;
	margin: 10px;
}

.poster-button {
	padding: 10px 20px;
	outline: none;
	border: none;
	cursor: pointer;
	border: 2px solid #f2ed5e;
	border-radius: 10px;
	width: 100%;
	background-color: #252259;
	color: #f2ed5e;
}
.poster-button:hover,
.poster-button:focus {
	border: 2px solid #252259;
	color: #252259;
	background-color: #f2ed5e;
}

@media (max-width: 766px) {
	.webinars {
		width: 90%;
		padding: 5%;
	}

	.postercards-container {
		flex-direction: column;
	}

	.poster-card {
		max-width: 90%;
		height: fit-content;
		margin: 5%;
	}
}
