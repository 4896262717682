* {
	margin: 0;
	padding: 0;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: linear-gradient(83.12deg, #c6d8ff 0%, #f2ed5e 99.42%);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Navbar*/
.navbar {
	height: 80px;
	background-color: #252259;
	color: #f2ed5e;
	position: sticky;
	top: 0;
}

.nav-content-box {
	width: 90%;
	height: 100%;
	min-width: max-content;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav-links a {
	display: inline-block;
	padding: 20px;
	color: #f2ed5e;
	text-decoration: none;
}

.nav-right-menu {
	display: none;
	width: 40px;
	height: auto;
}

.nav-right-close {
	width: 40px;
	height: auto;
}

.home-icon {
	width: 25px;
	height: auto;
}

.nav-ham {
	background-color: #252259;
	width: max-content;
	height: max-content;
	z-index: 10;
	position: fixed;
	right: 0;
}

.nav-ham a {
	display: block;
	padding: 20px;
	color: #f2ed5e;
	text-decoration: none;
}

/* Hero*/
.hero {
	height: 400px;
	text-align: center;
	font-size: large;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url("../img/eng-dwg.jpg") center center/cover no-repeat fixed;
}

.logo {
	width: 350px;
}

.hero-text {
	background-color: #252259;
	color: #f2ed5e;
	padding: 2px;
}

.intro-text {
	height: 250px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: large;
}
/* Main Content*/
.main-content-box {
	height: 1600px;
}

.main-content {
	width: 80%;
	margin: 0 auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.main-content-card {
	background-color: #252259;
	color: #f2ed5e;
	width: 80%;
	min-width: 250px;
	height: 22%;
	border-radius: 30px;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.text-only {
	display: unset;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.img-box {
	display: flex;
	justify-content: center;
	align-items: center;
}
.card-img {
	width: 80%;
	height: auto;
	margin: 5%;
	border: 1px solid black;
	border-radius: 20px;
}

.card-text {
	margin: 10%;
	border-radius: 20px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.card-button {
	padding: 10px;
	border-radius: 10px;
	width: fit-content;
	cursor: pointer;
	border: 2px solid #f2ed5e;
	color: #f2ed5e;
	background-color: #252259;
}
.card-button:hover,
.card-button:focus {
	border: 2px solid #252259;
	color: #252259;
	background-color: #f2ed5e;
}

/* Testimonials*/
.testimonials-box {
	height: 250px;
}

.testimonials {
	width: 60%;
	margin: 0 auto;
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

/* Footer*/
.footer {
	height: 400px;
	background-color: #252259;
	color: #f2ed5e;
}
.footer-content {
	width: 50%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.footer-icons a {
	text-decoration: none;
	color: #f2ed5e;
	text-align: center;
	padding: 50px;
}

.f-icon {
	width: 40px;
	height: 40px;
}

/*Responsive Design*/

@media (max-width: 1000px) {
	.main-content {
		width: 100%;
	}
	.footer-content {
		width: 100%;
	}
}

@media (max-width: 756px) {
	.nav-right {
		display: none;
	}

	.nav-right-menu {
		display: unset;
	}

	.main-content-card {
		width: 95%;
		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr;
	}

	.card-img {
		display: none;
	}
	.img-box {
		display: none;
	}

	.text-only {
		display: unset;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
	}

	.text-only p {
		text-align: center;
		padding: 2%;
	}

	.testimonials-box {
		height: max-content;
	}
	.testimonials {
		width: 90%;
		padding-bottom: 5%;
	}
}

@media (max-width: 450px) {
	.logo {
		width: 80%;
	}

	.hero-text {
		padding: 2px;
		font-size: small;
		margin: 0 5%;
	}

	.intro-text {
		font-size: medium;
		margin: 0 5%;
		height: max-content;
		padding-top: 25px;
	}

	.footer-icons a {
		padding: 12px;
	}

	.hero {
		height: 250px;
	}
}
