.placeholder-block {
	height: 100vh;
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;
}
